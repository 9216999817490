import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { getColor } from './OrderStatus';
import { getIcon } from './OrderIcon';
import ChipAvatarTransparent from './ChipAvatarTransparent';

const OrderChip = ({ status }) => (
  <Chip
    avatar={
      <ChipAvatarTransparent
        color={getColor(status)}
        icon={getIcon(status)}
      />
    }
    label={status}
    color="primary"
    style={{
      backgroundColor: getColor(status),
    }}
  />
);

OrderChip.propTypes = {
  status: PropTypes.string.isRequired,
};

export default OrderChip;
