import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useNavigate, useLocation } from '@reach/router';
import {
  Box,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { withLocation } from 'with-location';
import Collection from 'q3-admin/src/containers/collection';
import { Definitions } from 'q3-admin/src/containers';
import TextField from '@material-ui/core/TextField';
import Page from 'q3-admin/src/containers/page';
import { AuthContext } from 'q3-ui-permissions';

const getSortingPref = (state) =>
  get(state, 'profile.sorting', {});

const handleNavigation = (location, navigate) => {
  const q = new URLSearchParams(get(location, 'query', ''));

  return {
    defaultSearchValue: q.get('query'),

    onSearch: (e) => {
      if (e?.key !== 'Enter') return null;
      const v = e?.target?.value;
      if (v) q.set('query', v);
      else q.delete('query');
      return navigate(`?${q}`);
    },
    onSort: (sort) => {
      q.set('sort', sort);
      return navigate(`?${q}`);
    },
  };
};

export const useSorting = () => {
  const { state, update } = React.useContext(AuthContext);
  const { collectionName } = React.useContext(Definitions);
  const { onSort } = handleNavigation(
    useLocation(),
    useNavigate(),
  );

  return (sort) =>
    update(
      {
        sorting: {
          ...getSortingPref(state),
          [collectionName]: sort,
        },
      },
      () => onSort(sort),
    );
};

export const TableSearch = () => {
  const { defaultSearchValue, onSearch } = handleNavigation(
    useLocation(),
    useNavigate(),
  );

  return (
    <TextField
      name="search"
      type="search"
      fullWidth
      label="Search"
      variant="filled"
      onKeyPress={onSearch}
      helperText="Press enter to filter results"
      defaultValue={defaultSearchValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

const TableLoading = withLocation(
  ({ children, ...rest }) => {
    return (
      <Collection
        {...rest}
        disableHeader
        location={{
          ...rest.location,
          search: rest?.location?.search
            ? rest.location.search.replace(
                'query',
                'search',
              )
            : '',
        }}
      >
        <Paper>
          <Box p={2}>
            <TableSearch />
            <Page
              {...rest}
              defaultSortPreference="-updatedAt"
            >
              {children}
            </Page>
          </Box>
        </Paper>
      </Collection>
    );
  },
);

TableLoading.propTypes = {
  children: PropTypes.func.isRequired,
};

export default TableLoading;
