import React from 'react';
import PropTypes from 'prop-types';
import { Status } from 'q3-components';
import {
  red,
  purple,
  indigo,
  blue,
  cyan,
  teal,
  green,
  yellow,
  amber,
  orange,
  deepOrange,
  blueGrey,
} from '@material-ui/core/colors';
import {
  QUOTE,
  UNDER_REVIEW,
  BACKORDERED,
  DECLINED,
  OPEN,
  CONFIRMED,
  READY_FOR_DELIVERY,
  PREPAID,
  PAID,
  PARTIALLY_COMPLETED,
  PARTIALLY_COMPLETED_WITHOUT_BALENCE,
  COMPLETED,
  CANCELLED,
  REFUNDED,
} from './constants';

export const getColor = (value) => {
  switch (value) {
    case QUOTE:
    case 'Quote (Forecast)':
      return blue[900];
    case UNDER_REVIEW:
      return yellow[900];
    case BACKORDERED:
      return amber[900];
    case DECLINED:
      return deepOrange[900];
    case OPEN:
      return blueGrey[900];
    case CONFIRMED:
      return blueGrey[600];
    case READY_FOR_DELIVERY:
      return blue[500];
    case PREPAID:
      return orange[900];
    case PAID:
      return cyan[900];
    case PARTIALLY_COMPLETED:
      return teal[900];
    case PARTIALLY_COMPLETED_WITHOUT_BALENCE:
      return indigo[900];
    case COMPLETED:
      return green[900];
    case CANCELLED:
      return red[900];
    case REFUNDED:
      return purple[900];
    default:
      return undefined;
  }
};

const OrderStatus = ({ status }) => (
  <Status label={status} color={getColor(status)} />
);

OrderStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default OrderStatus;
