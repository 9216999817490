import React from 'react';
import Tabs from 'q3-ui/src/tabs';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Router } from '@reach/router';
import Container from '@material-ui/core/Container';
import MacSerialExporter from 'gatsby-theme-gentek/src/components/MacSerialExporter';
import MacSerialExporterFilter from 'gatsby-theme-gentek/src/components/MacSerialExporterFilter';
import Dashboard from '../containers/Dashboard';
import ChangePassword from '../containers/ChangePassword';
import Orders from '../containers/Orders';
import Returns from '../containers/Returns';
import ReturnsDetail from '../containers/ReturnsDetail';
import Invoice from '../containers/orders/detail';
import IsLoggedIn from '../components/IsLoggedIn';
import Profile from '../containers/Profile';

const MacSerialExporterWithFilter = () => (
  <Paper>
    <Box p={2}>
      <MacSerialExporter includeCustomerName>
        <MacSerialExporterFilter />
      </MacSerialExporter>
    </Box>
  </Paper>
);

const AccountTabs = () => (
  <Router basepath="/account">
    <Tabs
      root="/account"
      path="/*"
      views={[
        {
          to: '/',
          label: 'dashboard',
          component: Dashboard,
        },
        {
          to: '/orders',
          label: 'ordersAndQuotes',
          component: Orders,
        },
        {
          to: '/serials',
          label: 'serials',
          component: MacSerialExporterWithFilter,
        },
        {
          to: '/returns',
          label: 'returns',
          component: Returns,
        },
        {
          to: '/profile',
          label: 'profile',
          component: Profile,
        },
        {
          to: '/change-password',
          label: 'password',
          component: ChangePassword,
        },
      ]}
    />
  </Router>
);

const AccountPage = () => (
  <IsLoggedIn>
    <Box component="main" my={2}>
      <Container maxWidth="lg">
        <Router>
          <Invoice path="account/orders/:id" />
          <ReturnsDetail path="account/returns/:id" />
          <AccountTabs path="account/*" />
        </Router>
      </Container>
    </Box>
  </IsLoggedIn>
);

export default AccountPage;
