import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import { Link } from '@reach/router';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ellipsis } from './helpers';
import { Logo } from './VendorLogo';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    width: 375,
  },
}));

const VendorCard = ({ name, description, logo }) => {
  const { t } = useTranslation('labels');
  const cls = useStyles();

  return (
    <Card className={cls.root}>
      <CardContent>
        <Link to={`/${name}`}>
          <Logo src={logo} alt={name} />
        </Link>
        <Typography variant="body2">
          {ellipsis(description)}
        </Typography>
        <CardActions>
          <Box mt={2} width="100%" textAlign="center">
            <Grid container spacing={1} justify="center">
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  component={Link}
                  to="/"
                >
                  {t('viewProfile')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  component={Link}
                  to="/"
                >
                  {t('viewProducts')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default VendorCard;
