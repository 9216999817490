import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import EventIcon from '@material-ui/icons/Event';
import { purple } from '@material-ui/core/colors';
import ChipAvatarTransparent from './ChipAvatarTransparent';

const DateChip = ({ date }) => (
  <Chip
    avatar={
      <ChipAvatarTransparent
        color={purple[900]}
        icon={EventIcon}
      />
    }
    label={moment(date).format('lll')}
    color="primary"
    style={{
      backgroundColor: purple[900],
    }}
  />
);

DateChip.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateChip;
