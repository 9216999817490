import React from 'react';
import { Status } from 'q3-components';
import {
  orange,
  indigo,
  red,
  purple,
  green,
  teal,
  blue,
  deepOrange,
  cyan,
} from '@material-ui/core/colors';
import PropTypes from 'prop-types';

export const getColor = (status) => {
  const str = status.toLowerCase();

  if (str.includes('requested')) {
    return orange[500];
  }
  if (str.includes('issued')) {
    return indigo[500];
  }
  if (str.includes('declined')) {
    return red[500];
  }
  if (str.includes('expired')) {
    return blue[500];
  }
  if (str.includes('tested')) {
    return purple[500];
  }
  if (str.includes('verified')) {
    return teal[500];
  }
  if (str.includes('replacement')) {
    return deepOrange[500];
  }
  if (str.includes('shipped')) {
    return green[500];
  }
  if (str.includes('received')) {
    return green[900];
  }

  return cyan[500];
};

const ReturnStatus = ({ status }) => (
  <Status label={status} color={getColor(status)} />
);

ReturnStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ReturnStatus;
