import React from 'react';
import Typography from '@material-ui/core/Typography';
import { navigate } from '@reach/router';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import DateChip from 'gatsby-theme-gentek/src/components/DateChip';
import Box from '@material-ui/core/Box';
import OrderChip from 'gatsby-theme-gentek/src/components/ReturnChip';
import FromTo from 'gatsby-theme-gentek/src/components/FromTo';
import Collection from 'q3-admin/src/containers/collection';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import { Page, SubDetail } from 'q3-admin/src/containers';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReturnItemAdd from 'gatsby-theme-gentek/src/components/ReturnItemAdd';
import Dialog from 'q3-ui-dialog';
import ShippingSyncTo from 'gatsby-theme-gentek/src/components/ShippingSyncTo';
import Tile from 'q3-ui/src/tile';
import { Columns } from './orders/detail';

const goToReturns = () => navigate('/account/returns');

export default ({ id, ...rest }) => (
  <Collection
    {...rest}
    id={id}
    disableHeader
    collectionName={COLLECTIONS.RETURNS.COLLECTION_NAME}
    resourceName={COLLECTIONS.RETURNS.RESOURCE_NAME}
    resourceNameSingular={
      COLLECTIONS.RETURNS.RESOURCE_NAME_SINGULAR
    }
  >
    <Page
      id={id}
      loadingComponent={<CircularProgress />}
      {...rest}
    >
      {({ data: returnDetail, patch }) => {
        const isEditable =
          returnDetail.status === 'RMA drafted';

        const isSubmitable =
          returnDetail.items &&
          returnDetail.items.length > 0 &&
          isEditable;

        return (
          <>
            <Button onClick={goToReturns}>
              <KeyboardArrowLeft />
              Back to Returns
            </Button>
            <Tile title="returnDetail">
              <SubDetail
                th="product"
                TileProps={{
                  title: 'return',
                  subtitle: 'return',
                }}
                disableSearch
                disableSearchWhenEmpty
                renderBottom={
                  isSubmitable ? (
                    <Box mt={2}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          patch()({
                            status:
                              'RMA requested - Pending approval',
                          })
                        }
                      >
                        Submit to Gentek
                      </Button>
                    </Box>
                  ) : null
                }
                renderTop={
                  <Box mb={4}>
                    <Typography
                      variant="h2"
                      component="h1"
                      gutterBottom
                    >
                      #{returnDetail.seq}
                    </Typography>
                    {isEditable && (
                      <Box my={2}>
                        <Alert severity="info">
                          You can always come back to this
                          RMA at a later date; all progress
                          is automatically saved. Once
                          submitted for review, editing will
                          be disabled.
                        </Alert>
                      </Box>
                    )}
                    <Columns>
                      <OrderChip
                        status={returnDetail.status || '--'}
                      />
                      <DateChip
                        label={returnDetail.updatedAt}
                      />
                    </Columns>
                    <FromTo>
                      <Dialog
                        title="Shipping address"
                        renderContent={(close) => (
                          <ShippingSyncTo
                            forceEnable
                            populateFromCompany
                            populateFromContact
                            done={close}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Save
                            </Button>
                          </ShippingSyncTo>
                        )}
                        renderTrigger={(open) =>
                          isEditable ? (
                            <Button
                              fullWidth
                              variant="outlined"
                              color="secondary"
                              onClick={open}
                            >
                              Change/set address
                            </Button>
                          ) : null
                        }
                      />
                    </FromTo>
                    {returnDetail.comment && (
                      <Box py={1}>
                        <Alert severity="info">
                          <AlertTitle color="inherit">
                            Processing comments
                          </AlertTitle>
                          {returnDetail.comment}
                        </Alert>
                      </Box>
                    )}
                  </Box>
                }
                addComponent={
                  isEditable ? <ReturnItemAdd /> : <div />
                }
                disableMultiselect
                disableEditor
                disableRemove={!isEditable}
                root="items"
                cardProps={{
                  title: (item) => item.product,
                  describe: (item) =>
                    !item.hasExpired
                      ? 'In warranty'
                      : 'Out of warranty',
                  attributes: ['serial', 'mac'],
                }}
              />
            </Tile>
          </>
        );
      }}
    </Page>
  </Collection>
);
