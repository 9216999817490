import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from 'q3-ui/src/wrapper';
import { HeaderTagline } from 'q3-blocks';
import Box from '@material-ui/core/Box';

const ArchiveLayout = ({ children, ...rest }) => (
  <>
    <Box pt={2}>
      <HeaderTagline {...rest} />
    </Box>

    <Wrapper dense backgroundColor="transparent">
      {children}
    </Wrapper>
  </>
);

ArchiveLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default ArchiveLayout;
