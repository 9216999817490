import React from 'react';
import PropTypes from 'prop-types';
import Asset from 'q3-ui-assets';
import moment from 'moment';
import {
  CircularProgress,
  Box,
  Table,
  TableCell,
  TableRow,
  TableHead,
} from '@material-ui/core';
import { join, map, sortBy } from 'lodash';
import useRest from 'q3-ui-rest';
import { useLocation, Link } from '@reach/router';
import Pagination from 'q3-ui-datatables/src/Pagination';

const uppercase = (v) => String(v).toUpperCase();

const formatAddresses = (a) =>
  join(map(a, uppercase), ', ');

const MacSerialExport = ({
  children,
  includeCustomerName,
}) => {
  const location = useLocation();

  const d = useRest({
    url: '/serials',
    pluralized: 'orders',
    key: 'orders',
    runOnInit: true,
    location,
  });

  if (d.fetching)
    return (
      <Box p={4} textAlign="center" width="100%">
        <CircularProgress />
      </Box>
    );

  if (d.fetchingError)
    return <Asset icon="Error" title="error" />;

  return (
    <Box position="relative">
      {children}
      <Box mt={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th">Order #</TableCell>
              <TableCell component="th">Customer</TableCell>
              <TableCell component="th">Item</TableCell>
              <TableCell component="th">
                Addresses
              </TableCell>
              <TableCell component="th">
                Ship date
              </TableCell>
            </TableRow>
          </TableHead>
          {map(d.serials, (order) =>
            map(
              sortBy(
                order.fulfillment,
                'shipDate',
              ).reverse(),
              (item, i) => {
                const isFirst = i === 0;
                const seq = isFirst ? order.seq : '';

                return (
                  <TableRow>
                    <TableCell>
                      <strong>
                        {includeCustomerName ? (
                          <Link
                            to={`/app/orders/${order._id}/tracking`}
                          >
                            {seq}
                          </Link>
                        ) : (
                          seq
                        )}
                      </strong>
                    </TableCell>
                    {includeCustomerName && (
                      <TableCell>
                        {isFirst ? order?.customer : ''}
                      </TableCell>
                    )}
                    <TableCell style={{ padding: 8 }}>
                      {item.item}
                    </TableCell>
                    <TableCell>
                      {formatAddresses(item.addresses)}
                    </TableCell>
                    <TableCell style={{ minWidth: 118 }}>
                      {item.shipDate
                        ? moment(item.shipDate)
                            .add(5, 'hour')
                            .format('YYYY-MM-DD')
                        : ''}
                    </TableCell>
                  </TableRow>
                );
              },
            ),
          )}
        </Table>
        <Box py={1} width="100%">
          <Pagination {...d} />
        </Box>
      </Box>
    </Box>
  );
};

MacSerialExport.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.element,
  ]),
  includeCustomerName: PropTypes.bool,
};

MacSerialExport.defaultProps = {
  children: null,
  includeCustomerName: false,
};

export default MacSerialExport;
