import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'q3-ui-forms/src/builders';
import Box from '@material-ui/core/Box';
import {
  Store,
  Definitions,
} from 'q3-admin/src/containers/state';
import { asOptions } from 'q3-ui-forms/src/helpers';
import {
  getSafelyForAutoCompleteWithProjection,
  getSafelyForAutoComplete,
} from 'q3-ui-rest';
import { useTranslation } from 'q3-ui-locale';
import { get, map, join, size } from 'lodash';

export const getAddresses = (values) => {
  let mac;
  let serial;

  const v = get(values, 'product.addresses', []);

  if (size(v) === 2) {
    [serial, mac] = v;
  } else if (size(v) === 1) {
    const [item] = v;
    if (item.length === 12) mac = item;
    else serial = item;
  }

  return {
    mac,
    serial,
  };
};

const ItemAdd = ({ create, ...rest }) => {
  const { t } = useTranslation();
  const {
    data: { id, type, vendor, ...store },
  } = React.useContext(Store);
  const { collectionName } = React.useContext(Definitions);

  const isVendor = type === 'Vendor';

  return (
    <Box py={1} mb={2}>
      <Form
        {...rest}
        restart
        enableReset
        onSubmit={create}
        submitLabel={t('labels:addProduct')}
        initialValues={{
          operation: 'lookup',
        }}
        marshal={{
          operation: 'operation',
          product: [
            (v, values) => {
              return (
                get(values, 'product.item') ||
                get(values, 'sku.value') ||
                get(values, 'sku')
              );
            },
          ],
          serial: [
            (v, values) =>
              getAddresses(values)?.serial || v,
          ],
          mac: [
            (v, values) => getAddresses(values)?.mac || v,
          ],
          warranty: 'product.shipDate',
          description: 'description',
          reasonForReturn: 'reasonForReturn',
          invoice: 'invoice',
        }}
      >
        <Field
          type="radio"
          name="operation"
          under="items"
          label={t('labels:productLookupStrategy')}
          helperText={t('helpers:productLookupStrategy')}
          collapse={false}
          options={[
            { label: 'MAC/SERIAL', value: 'lookup' },
            { label: 'SKU', value: 'manual' },
          ]}
        />
        <Field
          name="product"
          type="autocomplete"
          under="items"
          label={t('labels:searchByMacOrSerial')}
          helperText={t('helpers:searchByMacOrSerial')}
          conditional={['operation=lookup']}
          loadOptions={(e) =>
            getSafelyForAutoCompleteWithProjection(
              `/products-address?customer=${store?.for?.ref}`,
              'addresses',
              'item',
            )(e).then((r) =>
              map(r, (item) => ({
                ...item,
                label: `${join(
                  item.addresses,
                  ' / ',
                )} (SKU: ${item.item})`,
              })),
            )
          }
          filterSelectedOptions={false}
          disableFilter
          xs={12}
          xl={12}
          lg={12}
        />
        <Field
          name="sku"
          type="autocomplete"
          under="items"
          label="SKU"
          conditional={['operation=manual']}
          freeSolo
          loadOptionsPlainly
          loadOptions={
            isVendor
              ? (e) =>
                  getSafelyForAutoCompleteWithProjection(
                    `/products?vendor.referenceID=${vendor?.ref}`,
                    'products',
                    'sku',
                  )(e).then((resp) => map(resp, 'sku'))
              : getSafelyForAutoComplete(
                  `/${collectionName}/${id}/autocomplete`,
                  'skus',
                )
          }
          xs={12}
          xl={12}
        />
        <Field
          name="serial"
          conditional={['operation=manual']}
          xl={6}
          lg={6}
        />
        <Field
          name="mac"
          conditional={['operation=manual']}
          xl={6}
          lg={6}
        />
        <Field
          name="reasonForReturn"
          type="select"
          under="items"
          options={asOptions([
            'Credit',
            'Credit for defective',
            'Defective',
            'DOA',
            'Software Upgrade',
            'Other',
          ])}
          xl={12}
          lg={12}
        />
        <Field
          name="invoice"
          type="text"
          under="items"
          conditional={[
            'reasonForReturn=Credit,Credit for defective',
          ]}
          xl={12}
          lg={12}
        />
        <Field
          xl={12}
          lg={12}
          required
          name="description"
          under="items"
          type="text"
          multiline
          rows={5}
        />
      </Form>
    </Box>
  );
};

ItemAdd.propTypes = {
  create: PropTypes.func.isRequired,
};

export default ItemAdd;
