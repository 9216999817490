import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import IconButton from 'q3-ui/src/iconButton';
import { useTranslation } from 'q3-ui-locale';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const InvoiceDownload = ({ id, invoicedOn, seq }) => {
  const { t } = useTranslation('labels');

  const handleClick = (e) => {
    e.preventDefault();
    return axios
      .get(`/orders/${id}/uploads`)
      .then(({ data: { uploads = [] } }) => {
        const f = uploads.find((u) =>
          new RegExp(`[A-Z]{2}${seq}-IN`, 'gi').test(
            u.name,
          ),
        );

        if (!f) throw new Error('No attachment found.');
        window.open(f.url);
      })
      .catch(() => {
        // eslint-disable-next-line
        alert('Could not fetch invoice.');
        // noop
      });
  };

  return (
    <IconButton
      label={t('download')}
      icon={CloudDownloadIcon}
      buttonProps={{
        onClick: handleClick,
        disabled:
          !Array.isArray(invoicedOn) || !invoicedOn.length,
      }}
    />
  );
};

InvoiceDownload.propTypes = {
  id: PropTypes.string.isRequired,
  seq: PropTypes.number.isRequired,
  invoicedOn: PropTypes.arrayOf(PropTypes.string),
};

InvoiceDownload.defaultProps = {
  invoicedOn: [],
};

export default InvoiceDownload;
