import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Graphic from 'q3-ui-assets';
import Paper from '@material-ui/core/Paper';
import ProductInformation from './ProductInformation';
import SplitLayout from './SplitLayout';

const ProductSection = ({ children }) => (
  <Paper elevation={0}>
    <Box component="section">{children}</Box>
  </Paper>
);

ProductSection.propTypes = {
  children: PropTypes.node.isRequired,
};

const ProductSkeleton = () => (
  <Fade in>
    <Box p={8} align="center">
      <CircularProgress />
    </Box>
  </Fade>
);

const ProductContent = ({ show, children }) => (
  <Fade in={show} timeout={350}>
    <Box>{children}</Box>
  </Fade>
);

ProductContent.propTypes = {
  children: PropTypes.node.isRequired,
};

const Section = ({ children, sku, manufacturerSku }) => (
  <>
    {manufacturerSku && (
      <Typography variant="overline">
        ManSku #{manufacturerSku}
      </Typography>
    )}
    <Typography variant="h1" gutterBottom>
      {sku}
    </Typography>
    {children}
  </>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  sku: PropTypes.string.isRequired,
  manufacturerSku: PropTypes.string,
};

Section.defaultProps = {
  manufacturerSku: null,
};

const ProductError = () => (
  <Graphic
    icon="Error"
    title="failedToLoadProduct"
    description="failedToLoadProduct"
  />
);

const ProductLayout = ({
  children,
  error,
  loading,
  ...data
}) => {
  if (loading) return <ProductSkeleton />;
  if (error) return <ProductError />;
  return <Section {...data}>{children}</Section>;
};

ProductLayout.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  data: PropTypes.shape({
    sku: PropTypes.string,
    manufacturerSku: PropTypes.string,
    photo: PropTypes.string,
  }).isRequired,
};

ProductLayout.defaultProps = {
  loading: false,
  error: false,
};

export default ProductLayout;
