import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { get } from 'lodash';
import { useTranslation } from 'q3-ui-locale';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';

const OrderFulfillmentTable = ({ items }) => {
  const { t } = useTranslation('labels');

  const getFrom = (...params) => get(...params, '--');

  const shipped = items.filter(
    (item) => item && (item.shipped || item.backordered),
  );

  return shipped.length ? (
    <Box mb={2} maxWidth="100%" overflow="auto">
      <Typography variant="h4" component="h3" gutterBottom>
        {t('fulfillment')}
      </Typography>

      <DataGrid
        autoHeight
        style={{
          fontSize: '0.833rem',
        }}
        disableSelectionOnClick
        disableColumnMenu
        rows={shipped.map((item, i) => ({
          id: i,
          item: getFrom(item, 'bucket.sku', 'N/A'),
          shipped: getFrom(item, 'shipped', 0),
          remaining: getFrom(item, 'backordered', 0),
        }))}
        columns={[
          {
            field: 'item',
            headerName: 'SKU',
            width: 190,
          },
          {
            headerName: 'Shipped',
            field: 'shipped',
            flex: 1,
            minWidth: 120,
          },
          {
            headerName: 'Remaining',
            field: 'remaining',
            flex: 1,
            minWidth: 120,
          },
        ]}
      />
    </Box>
  ) : (
    <Typography>{t('fulfillmentUnavailable')}</Typography>
  );
};

OrderFulfillmentTable.defaultProps = {
  items: [],
};

OrderFulfillmentTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line
      bucket: PropTypes.object,
      shipped: PropTypes.number,
      backordered: PropTypes.number,
    }),
  ),
};

export default OrderFulfillmentTable;
