import React from 'react';
import ProfileChangePassword from 'q3-admin/src/containers/ProfileChangePassword';
import SEOHelmet from '../components/SEOHelmet';

const PasswordChangeForm = (props) => (
  <>
    <SEOHelmet title="Account | Password" />
    <ProfileChangePassword {...props} />
  </>
);

export default PasswordChangeForm;
