import React from 'react';
import { get, uniqWith, isEqual, map, pick } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { array } from '@lolly-labs/q3-ui-helpers';
import { useTranslation } from 'q3-ui-locale';
import { DataGrid } from '@material-ui/data-grid';

const safeUpper = (x) =>
  typeof x === 'string' ? x.toUpperCase(x) : x;

const dedupe = (xs) =>
  uniqWith(
    map(xs, (item) =>
      pick(item, [
        'item',
        'addresses',
        'tracking',
        'shipDate',
      ]),
    ),
    isEqual,
  );

const OrderTrackingTable = ({ fulfillment }) => {
  const { t } = useTranslation('labels');

  return array.hasLength(fulfillment) ? (
    <>
      <Typography variant="h4" component="h3" gutterBottom>
        {t('tracking')}
      </Typography>
      <DataGrid
        autoHeight
        style={{
          fontSize: '0.833rem',
        }}
        disableSelectionOnClick
        disableColumnMenu
        rows={dedupe(fulfillment).map((item, i) => ({
          id: i,
          item: get(item, 'item', 'N/A'),
          ids: safeUpper(
            get(item, 'addresses', []).join(', '),
          ),
          tracking: item.tracking,
          invoicedOn: item.invoicedOn
            ? moment(item.invoicedOn).format('YYYY-MM-DD')
            : null,
          shipDate: moment(item.shipDate)
            .utc() // this comes from accpac
            .format('YYYY-MM-DD'),
        }))}
        columns={[
          {
            field: 'item',
            headerName: 'SKU',
            width: 190,
          },
          {
            headerName: 'IDs',
            field: 'ids',
            flex: 1,
            minWidth: 250,
          },
          {
            width: 210,
            headerName: 'Tracking',
            field: 'tracking',
            // eslint-disable-next-line
            renderCell: ({ value }) => {
              const v =
                value && typeof value === 'string'
                  ? value.replace(';', '')
                  : null;

              return v ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.purolator.com/en/shipping/tracker?pins=${v}`}
                >
                  {v}
                </a>
              ) : (
                ''
              );
            },
          },
          {
            width: 150,
            headerName: 'Ship date',
            field: 'shipDate',
          },
          {
            width: 150,
            headerName: 'Invoice date',
            field: 'invoicedOn',
          },
        ]}
      />
    </>
  ) : (
    <Typography>{t('trackingUnavailable')}</Typography>
  );
};

OrderTrackingTable.propTypes = {
  fulfillment: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.string,
      shipDate: PropTypes.string,
      tracking: PropTypes.string,
    }),
  ),
};

OrderTrackingTable.defaultProps = {
  fulfillment: [],
};

export default OrderTrackingTable;
