import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'q3-ui-forms/src/builders';
import Profile from 'q3-admin/src/containers/Profile';
import SEOHelmet from '../components/SEOHelmet';
import Addresses from './addresses';

const useStyle = makeStyles((theme) => ({
  root: {
    '& > div': {
      padding: 0,
      margin: 0,

      '& > div': {
        margin: 0,

        '& > article': {
          padding: 0,
          maxWidth: '100%',

          '& > section': {
            boxShadow: theme.shadows[1],
          },
        },
      },
    },
  },
}));

export default (props) => {
  const cls = useStyle();
  return (
    <Box className={cls.root}>
      <SEOHelmet title="Account | Profile" />
      <Profile
        {...props}
        fields={
          <Field
            name="phone"
            type="tel"
            required
            xl={6}
            lg={6}
          />
        }
        fieldKeys={['phone']}
        items={[
          {
            label: 'Addresses',
            component: Addresses,
          },
        ]}
      />
    </Box>
  );
};
