import React from 'react';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import PropTypes from 'prop-types';

const getIcon = (type) =>
  type === 'Vendor' ? OpenInBrowserIcon : MoveToInboxIcon;

const ReturnIcon = ({ status }) =>
  React.createElement(getIcon(status));

ReturnIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ReturnIcon;
