import React from 'react';
import { get } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import DashboardList from './DashboardList';

const FeaturedLinks = () => {
  const data = useStaticQuery(graphql`
    {
      links: allContentfulFeaturedLinks(
        sort: { order: ASC, fields: sortOrder }
      ) {
        nodes {
          name
          description {
            description
          }
          href
        }
      }
    }
  `);

  return (
    <DashboardList
      title="Featured Links"
      description="Curated resources from our vendors and partners to help your business stay on top of new releases, news and more."
      data={get(data, 'links.nodes', []).map((link) => ({
        ...link,
        description: link?.description?.description,
      }))}
    />
  );
};

export default FeaturedLinks;
