import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Link } from 'gatsby';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
} from '@material-ui/core';

const DashboardList = ({ title, description, data }) => {
  return (
    <Box mt={1}>
      <Paper>
        <Box py={2}>
          <Box
            px={1.25}
            pb={1}
            mb={1}
            style={{
              borderBottom: '2px solid whitesmoke',
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="body1">
              {description}
            </Typography>
          </Box>
          <List
            style={{
              maxHeight: 450,
              minHeight: 450,
              overflow: 'auto',
            }}
          >
            {map(data, (item, idx) => (
              <ListItem
                key={item?.id || idx}
                button
                dense
                {...(item.to
                  ? {
                      component: Link,
                      to: item.to,
                    }
                  : {
                      component: 'a',
                      href: item.href,
                      target: '_blank',
                    })}
              >
                <ListItemText
                  primary={item.name}
                  secondary={item.description}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Paper>
    </Box>
  );
};

DashboardList.defaultProps = {
  data: [],
};

DashboardList.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      description: PropTypes.string,
      to: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
};

export default DashboardList;
