import React from 'react';
import Datatables from 'q3-ui-datatables';
import { string } from '@lolly-labs/q3-ui-helpers';
import moment from 'moment';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import OrderIcon from 'gatsby-theme-gentek/src/components/OrderIcon';
import OrderStatus from 'gatsby-theme-gentek/src/components/OrderStatus';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import IconButton from 'q3-ui/src/iconButton';
import TableLoading, {
  useSorting,
} from '../components/TableLoading';
import { getOrderId } from './utils';
import InvoiceDownload from './InvoiceDownload';
import { resumeOrder } from '../components/OrderProcess';
import SEOHelmet from '../components/SEOHelmet';

export const makeDescription = ({
  paymentOption,
  total,
  shippingOption,
}) => {
  let str = string.toPrice(total);
  if (paymentOption) str += ` with ${paymentOption}`;
  if (shippingOption) str += ` via ${shippingOption}`;
  return str;
};

export const makeName = ({ id, seq, cartName }) => {
  let str = String(seq);
  if (cartName) str += ` / ${cartName}`;
  if (getOrderId() === id) str += ' (IN CART)';
  return str;
};

export default () => (
  <>
    <SEOHelmet title="Account | Orders" />
    <TableLoading
      collectionName={COLLECTIONS.ORDERS.RESOURCE_NAME}
      resourceName={COLLECTIONS.ORDERS.RESOURCE_NAME}
      resourceNameSingular={
        COLLECTIONS.ORDERS.RESOURCE_NAME_SINGULAR
      }
    >
      {({ orders = [], ...dataProps }) => (
        <Datatables
          {...dataProps}
          disableMultiselect
          virtuals={[
            'name',
            'resume',
            'invoiceAttachment',
            'payment',
            'opportunityName',
            'PO',
          ]}
          onSort={useSorting()}
          aliasForName="seq"
          data={orders}
          defaultColumns={[
            'actions',
            'status',
            'PO',
            'opportunityName',
            'createdAt',
          ]}
          resolvers={({
            id,
            status,
            seq,
            currency,
            cartName,
            createdAt,
            confirmedOn,
            po,
            name,
            ...rest
          }) => ({
            ...rest,
            id,
            name: makeName({
              cartName,
              seq,
              id,
            }),
            description: (
              <div style={{ width: 230 }}>
                {makeDescription(rest)}
              </div>
            ),
            status: React.createElement(OrderStatus, {
              status,
            }),
            url: `/account/orders/${id}`,
            createdAt: moment(
              confirmedOn || createdAt,
            ).format('DD/MM/YYYY'),
            imgSrc: () =>
              React.createElement(OrderIcon, {
                status,
              }),
            actions: (
              <>
                <IconButton
                  icon={OfflineBoltIcon}
                  label="Resume in cart"
                  buttonProps={{
                    onClick: resumeOrder(id, currency),
                    disabled:
                      !cartName || status !== 'Quote',
                  }}
                />
                <InvoiceDownload
                  id={id}
                  seq={seq}
                  {...rest}
                />
              </>
            ),
            PO: po,
            opportunityName: name,
          })}
        />
      )}
    </TableLoading>
  </>
);
