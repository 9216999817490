import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Collapse from '@material-ui/core/Collapse';
import { navigate } from '@reach/router';
import Alert from 'q3-ui/src/alert';
import Button from '@material-ui/core/Button';
import { string } from '@lolly-labs/q3-ui-helpers';
import { map, merge, size } from 'lodash';
import Box from '@material-ui/core/Box';
import Collection from 'q3-admin/src/containers/collection';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tile from 'q3-ui/src/tile';
import DateChip from 'gatsby-theme-gentek/src/components/DateChip';
import OrderChip from 'gatsby-theme-gentek/src/components/OrderChip';
import PaymentChip from 'gatsby-theme-gentek/src/components/PaymentChip';
import FromTo from 'gatsby-theme-gentek/src/components/FromTo';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import { Page } from 'q3-admin/src/containers';
import Repeater from 'q3-ui-repeater';
import OrderItemAccordionBuilder from 'gatsby-theme-gentek/src/components/OrderItemAccordionBuilder';
import OrderSummary from 'gatsby-theme-gentek/src/components/OrderSummary';
import {
  OrderFulfillmentTable,
  OrderTrackingTable,
} from 'gatsby-theme-gentek/src/components';
import { makeStyles } from '@material-ui/core/styles';
import OrderProcess from '../../components/OrderProcess';

export const withLoading = (fn) => (args) => {
  if (args.fetching) return <CircularProgress />;
  return fn(args);
};

const useStyle = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',

    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 'auto',
      right: 'auto',
      margin: '1rem 0',
    },
  },
}));

export const Columns = ({ children }) => {
  const cls = useStyle();
  return (
    <Box className={cls.root}>
      <Grid container spacing={1}>
        {React.Children.map(children, (child, i) => (
          <Grid item key={i}>
            {child}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const goToOrders = () => navigate('/account/orders');

const OrderNotice = () => {
  const [notices, setNotices] = React.useState([]);
  React.useEffect(() => {
    axios('/notices?location=Orders')
      .then((r) => {
        setNotices(r.data.notices);
      })
      .catch(() => {
        // noop
      });
  }, []);

  return (
    <Collapse in={notices.length}>
      <Box>
        {notices.map((notice) => (
          <Alert
            type="warning"
            key={notice.id}
            label={notice.description}
          />
        ))}
      </Box>
    </Collapse>
  );
};

const OrderDetail = ({ id, ...rest }) => (
  <Collection
    {...rest}
    id={id}
    disableHeader
    collectionName={COLLECTIONS.ORDERS.COLLECTION_NAME}
    resourceName={COLLECTIONS.ORDERS.RESOURCE_NAME}
    resourceNameSingular={
      COLLECTIONS.ORDERS.RESOURCE_NAME_SINGULAR
    }
  >
    <Page
      id={id}
      loadingComponent={<CircularProgress />}
      {...rest}
    >
      {({ data: order }) => (
        <>
          <Button onClick={goToOrders}>
            <KeyboardArrowLeft />
            Back to orders
          </Button>
          <OrderNotice />

          <Tile title="orderDetail">
            <Typography
              variant="h5"
              component="h1"
              color="primary"
              gutterBottom
            >
              #WO {order.seq} ({order.currency})
            </Typography>
            <Columns>
              <OrderChip status={order.status} />
              <DateChip date={order.updatedAt} />
              <PaymentChip {...order} />
            </Columns>
            <Box mt={2}>
              <FromTo />
            </Box>
            <Box mb={2}>
              <OrderItemAccordionBuilder>
                {({ cardProps, ...etc }) => (
                  <Repeater
                    {...etc}
                    th="product"
                    emptyComponent={<div />}
                    disableSearch
                    disableRemove
                    disableEditor
                    edit={() => null}
                    data={map(order.items, (item) => ({
                      ...item,
                      price: string.toPrice(item.price),
                      subtotal: string.toPrice(
                        item.subtotal,
                      ),
                    }))}
                    collectionName="orders"
                    addComponent={<div />}
                    cardProps={merge(cardProps, {
                      attributes: [
                        'quantity',
                        'price',
                        'subtotal',
                      ],
                    })}
                  />
                )}
              </OrderItemAccordionBuilder>
            </Box>
            {size(order.items) > 0 && (
              <Box bgcolor="background.default" p={3}>
                <OrderFulfillmentTable
                  items={order.items}
                />
                <OrderTrackingTable
                  fulfillment={order.fulfillment}
                />
              </Box>
            )}
            <OrderSummary />
            {order.comments && (
              <Box
                my={2}
                p={2}
                style={{ backgroundColor: 'whitesmoke' }}
              >
                <Typography variant="body2" gutterBottom>
                  <strong>Comments from Gentek:</strong>
                </Typography>
                <Typography>{order.comments}</Typography>
              </Box>
            )}
            <OrderProcess {...order} />
          </Tile>
        </>
      )}
    </Page>
  </Collection>
);

OrderDetail.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OrderDetail;
