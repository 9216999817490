import PropTypes from 'prop-types';
import { useTranslation } from 'q3-ui-locale';

const VerboseBoolean = ({ truthy }) => {
  const { t } = useTranslation('labels');
  return truthy ? t('yes') : t('no');
};

VerboseBoolean.propTypes = {
  // eslint-disable-next-line
  truthy: PropTypes.any.isRequired,
};

export default VerboseBoolean;
