import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import CreditCard from '@material-ui/icons/CreditCard';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import { green } from '@material-ui/core/colors';
import ChipAvatarTransparent from './ChipAvatarTransparent';

const PaymentChip = ({
  paymentOption,
  isPayingWithCreditCard,
}) => (
  <Chip
    avatar={
      <ChipAvatarTransparent
        color={green[900]}
        icon={
          isPayingWithCreditCard
            ? CreditCard
            : CardMembershipIcon
        }
      />
    }
    label={paymentOption}
    color="primary"
    style={{
      backgroundColor: green[900],
    }}
  />
);

PaymentChip.propTypes = {
  paymentOption: PropTypes.string.isRequired,
  isPayingWithCreditCard: PropTypes.bool.isRequired,
};

export default PaymentChip;
