import React from 'react';
import { Link } from '@reach/router';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 225,
  },
  fit: {
    position: 'relative',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
}));

export const Logo = ({ src, alt }) => (
  <Box p={1}>
    <Image
      fluid={{
        src,
      }}
      alt={alt}
      style={{
        height: '115px',
      }}
      imgStyle={{
        objectFit: 'contain',
      }}
    />
  </Box>
);

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

const VendorLogo = ({ name, logo, to }) => {
  const cls = useStyles();

  return (
    <Card className={cls.root} raised>
      <CardActionArea component={Link} to={to}>
        <Logo src={logo} alt={name} />
      </CardActionArea>
    </Card>
  );
};

VendorLogo.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default VendorLogo;
