import React from 'react';
import { get, map } from 'lodash';
import moment from 'moment';
import useRest from 'q3-ui-rest';
import DashboardList from './DashboardList';

export const makeCompoundSkuSearch = (xs) => {
  const parts = map(xs?.requiredSkus, (v) =>
    v.toUpperCase(),
  ).join('|');

  return parts ? `/search?sku=/${parts}/` : '/products';
};

export const checkRebateVisibility = (xs) => {
  let show = true;

  if (xs.effectiveFrom)
    show = moment().isAfter(moment(xs.effectiveFrom));

  if (xs.expiresOn && show)
    show = moment().isBefore(moment(xs.expiresOn));

  return show;
};

const Rebates = () => {
  const rest = useRest({
    url: '/rebates',
    key: 'rebate',
    pluralized: 'rebates',
    runOnInit: true,
  });

  return (
    <DashboardList
      {...rest.fetching}
      title="Instant Rebates"
      description="Looking for some instant savings? Click a rebate below to search products that may be eligible. Conditions may apply."
      data={get(rest, 'rebates', [])
        .filter(checkRebateVisibility)
        .map((rebate) => ({
          ...rebate,
          to: makeCompoundSkuSearch(rebate),
        }))}
    />
  );
};

export default Rebates;
