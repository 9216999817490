import React from 'react';
import Datatables from 'q3-ui-datatables';
import { toDate } from 'gatsby-theme-gentek/src/components/helpers';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import ReturnIcon from 'gatsby-theme-gentek/src/components/ReturnIcon';
import ReturnVendorStatus from 'gatsby-theme-gentek/src/components/ReturnVendorStatus';
import Box from '@material-ui/core/Box';
import ReturnsNew from './ReturnsNew';
import TableLoading, {
  useSorting,
} from '../components/TableLoading';
import SEOHelmet from '../components/SEOHelmet';

export default () => (
  <Box display="flex" position="relative" maxWidth="100%">
    <SEOHelmet title="Account | Returns" />
    <Box style={{ flex: 1, width: 'calc(100% - 4rem)' }}>
      <TableLoading
        collectionName={COLLECTIONS.RETURNS.RESOURCE_NAME}
        resourceName={COLLECTIONS.RETURNS.RESOURCE_NAME}
        resourceNameSingular={
          COLLECTIONS.RETURNS.RESOURCE_NAME_SINGULAR
        }
      >
        {({ returns = [], ...dataProps }) => (
          <Datatables
            {...dataProps}
            data={returns}
            defaultColumns={[
              'status',
              'numberOfItems',
              'tracking',
              'lastUpdated',
            ]}
            disableMultiselect
            aliasForName="seq"
            virtuals={['numberOfItems', 'tracking']}
            onSort={useSorting()}
            resolvers={({
              id,
              createdAt,
              status,
              seq,
              total,
              type,
              items,
              shippingOptions,
              lastUpdated,
              ...rest
            }) => ({
              ...rest,
              id,
              name: `#${seq}`,
              status: React.createElement(
                ReturnVendorStatus,
                {
                  status: status || '--',
                },
              ),
              url: `/account/returns/${id}`,
              createdOn: toDate(createdAt),
              lastUpdated: toDate(lastUpdated),
              numberOfItems: items.length,
              tracking: shippingOptions ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.purolator.com/en/shipping/tracker?pins=${shippingOptions.tracking}`}
                >
                  {shippingOptions.tracking}
                </a>
              ) : (
                '--'
              ),
              photo: () =>
                React.createElement(ReturnIcon, {
                  status,
                }),
            })}
          />
        )}
      </TableLoading>
    </Box>

    <ReturnsNew />
  </Box>
);
