import React from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { navigate } from '@reach/router';
import { AuthContext } from 'q3-ui-permissions';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import Add from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

export default () => {
  const auth = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);

  const getBelongs = () => ({
    belongsTo: {
      ref: get(auth, 'state.profile.id'),
    },
  });

  const getFor = () => ({
    for: {
      ref: get(auth, 'state.profile.customer.ref'),
    },
  });

  const onCreate = () => {
    setLoading(true);
    return axios
      .post(COLLECTIONS.RETURNS.ENDPOINT, {
        type: 'Customer',
        ...getBelongs(),
        ...getFor(),
      })
      .then(({ data }) =>
        navigate(
          `.${COLLECTIONS.RETURNS.ENDPOINT}/${data.return.id}`,
        ),
      )
      .catch(() => {
        // eslint-disable-next-line
        alert(
          'Please contact GENTEK; The RMA service is currently down.',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box p={1}>
      <Fab color="secondary" onClick={onCreate}>
        {loading ? <CircularProgress /> : <Add />}
      </Fab>
    </Box>
  );
};
