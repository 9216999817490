import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'q3-ui-locale';
import Grid from '@material-ui/core/Grid';
import Fingerprint from '@material-ui/icons/Fingerprint';
import { Store } from 'q3-admin/src/containers/state';
import Address, { AddressLine } from 'q3-ui/src/address';

const FromTo = ({ children, paddingTop, ...rest }) => {
  const { t } = useTranslation('labels');

  const {
    billing: billingHelper,
    shipping: shippingHelper,
  } = get(rest, 'helpers', {});

  const {
    data: { billing, shipping, currency, for: madeFor },
  } = React.useContext(Store);

  const cus =
    currency === 'CAD'
      ? get(madeFor, 'canadianCode')
      : get(madeFor, 'americanCode');

  if (!billing && !shipping) return null;

  return (
    <Grid
      container
      spacing={4}
      style={{ paddingTop, paddingBottom: '1rem' }}
    >
      {billing && (
        <Grid item>
          <Address
            label={t('billTo')}
            {...billing}
            helper={billingHelper}
          />
          <div style={{ marginTop: '-.5rem' }}>
            <AddressLine
              icon={Fingerprint}
              label={t('customerCode')}
            >
              {cus}
            </AddressLine>
          </div>
        </Grid>
      )}

      <Grid item>
        {shipping && (
          <Address
            label={t('shipTo')}
            {...shipping}
            helper={shippingHelper}
          />
        )}
        {children}
      </Grid>
    </Grid>
  );
};

FromTo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  paddingTop: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

FromTo.defaultProps = {
  paddingTop: undefined,
};

export default FromTo;
