import React from 'react';
import TrackChanges from '@material-ui/icons/TrackChanges';
import AssignmentLate from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const getIcon = (status) => {
  switch (status) {
    case 'Quote':
      return TrackChanges;
    case 'Under Review':
      return AssignmentLate;
    case 'Declined':
      return ErrorIcon;
    case 'Partially Completed':
      return DoneIcon;
    case 'Completed':
      return DoneAllIcon;
    case 'Paid':
      return ReceiptIcon;
    default:
      return AssignmentTurnedIn;
  }
};

const OrderIcon = ({ status }) =>
  React.createElement(getIcon(status));

OrderIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default OrderIcon;
