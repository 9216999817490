import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  isChangeable,
  isReadyForCreditCardProcessing,
} from 'gatsby-theme-gentek/src/components/helpers';
import { Grid, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'q3-ui-locale';
import SplitButton from 'q3-ui/src/splitButton';
import {
  setOrderId,
  setOrderCurrency,
  isBrowserReady,
} from '../containers/utils';
import { done } from '../containers/CheckoutConfirm';

export const resumeOrder = (id, currency) => () => {
  if (isBrowserReady()) {
    setOrderId(id);
    setOrderCurrency(currency);
    window.location.replace('/products');
  }
};

const goToPaymentLink = (id) => () => {
  if (isBrowserReady())
    window.location.replace(`/pay?orderId=${id}`);
};

const OrderProcess = (props) => {
  const { isPayingWithCreditCard, id, status, currency } =
    props;

  const [loading, setLoading] = React.useState(false);
  const [err, setError] = React.useState(false);

  const { t } = useTranslation();
  const actions = [];

  if (status === 'Quote')
    actions.push({
      label: t('labels:confirm'),
      description: t('descriptions:confirmOrder'),
      handler: () => {
        setLoading(true);
        return axios
          .post(`/orders/${id}/confirm`)
          .then(() =>
            done({
              isPayingWithCreditCard,
              id,
            }),
          )
          .catch(() => {
            setError(true);
            setLoading(false);
          });
      },
    });

  if (isReadyForCreditCardProcessing(props))
    actions.push({
      label: t('labels:payNow'),
      description: t('descriptions:payNow'),
      handler: goToPaymentLink(id),
    });

  if (isChangeable(status))
    actions.push({
      label: t('labels:resumeOrder'),
      description: t('descriptions:resumeOrder'),
      handler: resumeOrder(id, currency),
    });

  return actions.length ? (
    <Grid container alignItems="center">
      <Grid item>
        <SplitButton size="large" options={actions} />
      </Grid>
      <Grid item>{loading && <CircularProgress />}</Grid>
      <Grid item xs={12} style={{ marginTop: '1rem' }}>
        {err && (
          <Alert severity="error">
            This action could not be completed. Please
            ensure that the order is ready for processing
            and/or is still valid.
          </Alert>
        )}
      </Grid>
    </Grid>
  ) : null;
};

OrderProcess.propTypes = {
  id: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isPayingWithCreditCard: PropTypes.bool.isRequired,
  canBeConfirmed: PropTypes.bool.isRequired,
};

export default OrderProcess;
