import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { navigate } from '@reach/router';
import { Adapters, Builders } from 'q3-ui-forms';
import { Box, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import { array } from '@lolly-labs/q3-ui-helpers';
import {
  convertFromRegexPattern,
  castToRegex,
} from 'q3-ui-forms/src/helpers';
import { getSafelyForAutoCompleteWithProjection } from 'q3-ui-rest';

export const Reset = () => {
  const { t } = useTranslation('labels');
  const handleClick = () => navigate('?');

  return (
    <Box ml={1} mt={1}>
      <Button type="button" onClick={handleClick}>
        {t('reset')}
      </Button>
    </Box>
  );
};

export const FormButtons = () => (
  <Grid item xs={12}>
    <Grid container alignItems="center">
      <Grid item>
        <Builders.Next label="submit" submit />
      </Grid>
      <Grid item>
        <Reset />
      </Grid>
    </Grid>
  </Grid>
);

const castToLocalStart = (v) =>
  moment(v, 'YYYY-MM-DD').isValid()
    ? moment(v)
        .endOf('day')
        .add(5, 'hour')
        .format('YYYY-MM-DD')
    : v;

const castToLocalEnd = (v) =>
  moment(v, 'YYYY-MM-DD').isValid()
    ? moment(v).add(5, 'hour').format('YYYY-MM-DD')
    : v;

const castToStart = (v) =>
  moment(v, 'YYYY-MM-DD').isValid()
    ? moment(v).subtract(5, 'hour').toDate()
    : v;

const castToEnd = (v) =>
  moment(v, 'YYYY-MM-DD').isValid()
    ? moment(v).endOf('day').subtract(5, 'hour').toDate()
    : v;

const arrToReg = (v) =>
  array.is(v).map(convertFromRegexPattern);

const MacSerialExporterFilter = ({ children }) => (
  <Adapters.EncodedUrl
    marshalSelectively
    marshal={{
      'fulfillment~item': [castToRegex],
      'fulfillment~addresses': [castToRegex],
      'fulfillment~shipDate>': [castToStart],
      'fulfillment~shipDate<': [castToEnd],
    }}
    modify={{
      'fulfillment~shipDate>': [castToLocalStart],
      'fulfillment~shipDate<': [castToLocalEnd],
      'fulfillment~item': [arrToReg],
      'fulfillment~addresses': [arrToReg],
    }}
  >
    <Builders.Field
      type="dateRange"
      name="fulfillment~shipDate"
    />
    {children}
    <Builders.Field
      type="chips"
      name="fulfillment~item"
      freeSolo
      helperText="Press Enter to lock-in search term and add others"
      loadOptions={getSafelyForAutoCompleteWithProjection(
        '/distinct?field=fulfillment.item&collectionName=orders',
        'values',
      )}
    />
    <Builders.Field
      freeSolo
      type="chips"
      name="fulfillment~addresses"
      helperText="Press Enter to lock-in search term and add others"
    />
    <FormButtons />
  </Adapters.EncodedUrl>
);

MacSerialExporterFilter.defaultProps = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.node,
  ]),
};

MacSerialExporterFilter.propTypes = {
  children: null,
};

export default MacSerialExporterFilter;
