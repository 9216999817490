import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { SubDetail } from 'q3-admin/src/containers';
import {
  Multistep,
  Fieldset,
} from 'q3-ui-forms/src/builders';
import { castToBoolean } from 'q3-ui-forms/src/helpers';
import {
  AddressLocationFieldset,
  AddressPrimaryContactFieldset,
} from 'gatsby-theme-gentek/src/components';
import { useAuth } from 'q3-ui-permissions';
import { Definitions } from 'q3-admin/src/containers/state';

const makeTitle = ({
  streetNumber,
  streetLine1,
  streetLine2,
  primary,
}) => {
  let base = `${streetNumber} ${streetLine1}`;
  if (streetLine2) base += `, ${streetLine2}`;
  if (primary) base += ' (Default location)';
  return base;
};

const isShipping = ({ kind }) => kind === 'Billing';

const CompanyAddressRepeater = () => {
  const s = useAuth();

  return (
    <>
      <Box mb={1}>
        <Alert severity="warning">
          To modify your billing address or to change a
          branch or default location, you'll need to get in
          touch with Gentek.
        </Alert>
      </Box>
      <Definitions.Provider
        value={{
          collectionName: 'q3-api-companies',
          id: s?.state?.profile?.customer?.ref,
        }}
      >
        <SubDetail
          disableMultiselect
          th="address"
          root="addresses"
          cardProps={{
            title: makeTitle,
            describe: 'kind',
            disableRemove: isShipping,
            disableEditor: isShipping,
          }}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            streetNumber: '',
            streetLine1: '',
            streetLine2: '',
            city: '',
            region: '',
            country: 'CA',
            postal: '',
            phone: '',
            primary: false,
            kind: 'Shipping',
            fax: '',
          }}
        >
          <Multistep
            marshalSelectively
            marshal={{
              primary: [castToBoolean],
              branch: [castToBoolean],
            }}
          >
            <Fieldset name="location">
              <AddressLocationFieldset />
            </Fieldset>
            <Fieldset name="contact">
              <AddressPrimaryContactFieldset
                contains={[
                  'email',
                  'firstName',
                  'lastName',
                ]}
                required={false}
              />
            </Fieldset>
          </Multistep>
        </SubDetail>
      </Definitions.Provider>
    </>
  );
};

export default CompanyAddressRepeater;
