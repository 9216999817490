import React from 'react';
import { Grid } from '@material-ui/core';
import SEOHelmet from '../components/SEOHelmet';
import FeaturedLinks from './FeaturedLinks';
import Rebates from './Rebates';

const Dashboard = () => (
  <>
    <SEOHelmet title="Account | Dashboard" />
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        <Rebates />
      </Grid>
      <Grid item md={6} xs={12}>
        <FeaturedLinks />
      </Grid>
    </Grid>
  </>
);

export default Dashboard;
